import i18next from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import type { LanguageType } from '../dtos';
// import translationsEnJson from '../translations.en.json';
// import translationsEsJson from '../translations.es.json';

import { getSetting } from './settings';
import { makeServiceUrl } from './utils';

// initialize i18n with an IIFE (immediately invoked function expression)
(async function () {
  i18next
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      // resources: {
      //   es: { translation: translationsEsJson },
      //   en: { translation: translationsEnJson },
      // },
      // lng: 'es', // if you're using a language detector, do not define the lng option
      fallbackLng: 'es',
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      backend: {
        loadPath: makeServiceUrl('translations/{{lng}}/{{ns}}'),
        addPath: makeServiceUrl('translations/add/{{lng}}/{{ns}}'),
        allowMultiLoading: true,
        crossDomain: false,
      },
    });

  const lang = await getSetting('lang');
  console.log('language from settings: ', lang);
  i18next.changeLanguage(lang);
})();

export const formatCurrencyText = (
  cents: number,
  lang: LanguageType,
  minimumFractionDigits = 2,
  productCurrency?: 'MXN' | 'USD'
): string => {
  const activeCurrency = lang === 'EN' ? 'USD' : 'MXN';
  const format = new Intl.NumberFormat(lang === 'EN' ? 'en-us' : 'es-MX', {
    style: 'currency',
    currency: activeCurrency,
    minimumFractionDigits,
  }).format(cents / 100);
  return productCurrency ? `${format} ${productCurrency}` : format;
};

type I18nReturnType = {
  t: any;
  langs: readonly string[];
  lang: LanguageType;
  setLang: (lang: LanguageType) => void;
  formatCurrency: (
    cents: number,
    currency?: 'MXN' | 'USD',
    minimumFractionDigits?: number
  ) => JSX.Element;
};

export const useI18n = (keyPrefix?: string): I18nReturnType => {
  const { t } = useTranslation(undefined, { keyPrefix });
  const lang = i18next.language.toUpperCase() as LanguageType;
  const formatCurrencyHTML = (
    cents: number,
    currency?: 'MXN' | 'USD',
    minimumFractionDigits = 2
  ): JSX.Element => {
    const withoutCurrency = formatCurrencyText(
      cents,
      lang,
      minimumFractionDigits
    );
    return (
      <CurrencySpan $isNegative={cents < 0}>
        {withoutCurrency}
        {currency && <CurrencyCodeLabel>{currency}</CurrencyCodeLabel>}
      </CurrencySpan>
    );
  };

  return {
    t,
    langs: Object.keys(i18next.services.resourceStore.data),
    setLang: (lang: LanguageType) => i18next.changeLanguage(lang.toLowerCase()),
    lang,
    formatCurrency: formatCurrencyHTML,
  };
};

export default useI18n;

interface CurrencySpanProps {
  $isNegative: boolean;
}

const CurrencySpan = styled.span<CurrencySpanProps>`
  color: ${(props) =>
    props.$isNegative ? 'var(--ion-color-negative-number)' : 'inherit'};
  position: relative;
`;

export const CurrencyCodeLabel = styled.span<{ color?: string }>`
  --size: 0.65rem;
  ${({ color }) => color && `color: var(--ion-color-${color});`};
  font-size: var(--size);

  display: inline-block;
  position: relative;
  top: calc((0.9rem - var(--size)) * -1);

  padding-left: 0.15rem;
`;

import type { ErrorInfo, ReactNode } from 'react';
import { Suspense, lazy, Component } from 'react';

import Loading from '../components/Loading';
import UnexpectedError from '../pages/UnexpectedError';

const HTTP_PAYMENT_REQUIRED = '402';

const OnBoarding = lazy(() => import('./OnBoardingApp'));

interface Props {
  children?: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, _errorInfo: ErrorInfo): void {
    console.error('enroll?', error.message.indexOf(HTTP_PAYMENT_REQUIRED));
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      if (
        this.state.error &&
        this.state.error.message?.indexOf(HTTP_PAYMENT_REQUIRED) > 0
      ) {
        return (
          <Suspense fallback={<Loading />}>
            <OnBoarding />
          </Suspense>
        );
      }
      return this.props?.fallback ? (
        this.props.fallback
      ) : (
        <UnexpectedError error={this.state.error} />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

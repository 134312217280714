import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import { useCurrentContact } from '../../services/auth/ContactContext';
import { useOrderDrafts } from '../../services/orderDraft';

const GenerateOrderWrapper: React.FC<
  RouteComponentProps<{
    from: string;
    draftKey?: string;
    omitDraft?: string;
  }>
> = ({ match }) => {
  const { supplierId } = useCurrentContact();
  const { drafts } = useOrderDrafts();
  const { from, omitDraft, draftKey } = match.params;
  const history = useHistory();
  const [supplierDraftsLength, setSupplierDraftsLength] = useState<number>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let buyerDraftsLength = 0;
    if (from === 'newOrder') {
      Object.entries(drafts)?.map(([key, draft]) => {
        const { buyerId } = draft;
        if (key.split('-')[0] === 'order' && buyerId === supplierId) {
          buyerDraftsLength = buyerDraftsLength + 1;
        }
      });
    } else {
      Object.entries(drafts)?.map(([key, draft]) => {
        const { sellerId } = draft;
        if (key.split('-')[0] === 'customerOrder' && sellerId === supplierId) {
          buyerDraftsLength = buyerDraftsLength + 1;
        }
      });
    }
    setSupplierDraftsLength(buyerDraftsLength);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading) {
      if (!supplierDraftsLength || Boolean(Number(omitDraft)) || draftKey) {
        return history.replace(`/generateOrder/${from}`);
      }
      return history.replace(`/multipleCarts/${from}`);
    }
  }, [history, omitDraft, draftKey, supplierDraftsLength, loading]);

  return null;
};

export default GenerateOrderWrapper;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import type { ReactNode } from 'react';

import { createDefaultDispatchers } from './dispatchers';
import type { ContextValue } from './useDraftReducer';
import useDraftMapReducer from './useDraftReducer';

const Context = React.createContext<ContextValue>({
  drafts: {},
  ...createDefaultDispatchers()
});

export function OrderDraftsContext({ children }: { children: ReactNode }): JSX.Element {
  const contextValue = useDraftMapReducer();
  return  <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export const useOrderDrafts = (): ContextValue => useContext(Context);


import { Contact } from '../../../dtos';
import { useCurrentContact } from '../../../services/auth/ContactContext';
import { isUserAuthorized } from '../../../services/withSecurityLevelRequired';

interface WithSecurityLevelProps {
  pageAccessLevel: number;
  pageSubscriptionLevel: number;
  isPageAccessLevelNegativeOneException?: boolean;
  children: JSX.Element;
  functionToValidate?: (data: Contact) => boolean;
}

const WithSecurityLevel: React.FC<WithSecurityLevelProps> = ({
  pageAccessLevel,
  pageSubscriptionLevel,
  isPageAccessLevelNegativeOneException,
  children,
  functionToValidate,
}) => {
  const contact = useCurrentContact();
  const { supplier, accessLevel } = contact;
  const subscriptionPlan = supplier?.subscriptionPlan;
  const isAuthorized = isUserAuthorized({
    userAccessLevel: accessLevel,
    userSubscriptionPlan: subscriptionPlan,
    pageAccessLevel,
    pageSubscriptionLevel,
    isPageAccessLevelNegativeOneException,
  });

  if (functionToValidate) {
    return functionToValidate(contact) && isAuthorized ? children : <></>;
  }

  if (isAuthorized) return children;

  return <></>;
};

export default WithSecurityLevel;

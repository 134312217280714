import { IonIcon, IonImg, IonModal } from '@ionic/react';
import styled from 'styled-components';
import { PLACEHOLDER_SRC } from './ImageUpload/ImageUploadRound';
import { useRef } from 'react';
import { closeOutline } from 'ionicons/icons';

interface ImageViewerProps {
  isOpen?: boolean;
  onDismiss?: () => void;
  src?: string;
  trigger?: string;
}

const ImageViewer = ({
  isOpen,
  onDismiss,
  src = PLACEHOLDER_SRC,
  trigger,
}: ImageViewerProps): JSX.Element => {
  const modalRef = useRef<HTMLIonModalElement>(null);

  const handleDismiss = () => {
    onDismiss?.();

    modalRef.current?.dismiss();
  };

  return (
    <Modal
      ref={modalRef}
      trigger={trigger}
      isOpen={isOpen}
      onDidDismiss={handleDismiss}
      showBackdrop
    >
      <Container>
        <PhotoIconReset
          size="large"
          icon={closeOutline}
          color="light"
          onClick={handleDismiss}
        />
        <Circle />
        <Img className="readonly" src={src} />
      </Container>
    </Modal>
  );
};

export default ImageViewer;

const Modal = styled(IonModal)`
  --backdrop-opacity: 0.5;
  --width: 90vw;
  --height: auto;

  @media only screen and (min-width: 768px) {
    --width: 50vw;
  }
`;

const Container = styled.div`
  max-height: 80vh;
  overflow: auto;
`;

const PhotoIconReset = styled(IonIcon)`
  position: absolute;
  right: 2%;
  top: 2%;
  z-index: 1;
`;

const Circle = styled.div`
  position: absolute;
  right: 2%;
  top: 2%;
  border-radius: 100%;
  width: 2em;
  height: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`;

const Img = styled(IonImg)`
  width: 100%;
  min-height: 20vh;
  max-height: 80vh;
`;

import { Storage } from '@ionic/storage';
import { useMemo, useReducer, useRef, useEffect } from 'react';

import useI18n from '../i18n';
import useToast from '../useToast';

import { createDispatchers } from './dispatchers';
import type { DispatchersObject } from './dispatchers';
import reducer from './reducer';
import type { Action } from './reducer';
import type { DraftMap } from './types';

export type ContextValue = DispatchersObject & {
  drafts: DraftMap;
};

const CURRENT_DRAFT_VERSION = 2;

const useDraftMapReducer = (): ContextValue => {
  const { t } = useI18n();
  const store = useRef<Storage>();

  const [drafts, dispatch] = useReducer((state: DraftMap, action: Action) => {
    const drafts = reducer(state, action);
    if (action.type !== 'initialize') {
      store.current?.set('drafts', drafts);
    }
    return drafts;
  }, {});

  const { showToast } = useToast();

  const dispatchers = useMemo(() => createDispatchers(dispatch), [dispatch]);

  useEffect(() => {
    async function initStore() {
      store.current = new Storage();
      await store.current.create();

      let storedDrafts: DraftMap = (await store.current.get('drafts')) ?? {};
      let storedDraftsVersion = parseInt(
        (await store.current.get('draftsVersion')) ?? '0'
      );

      if (Object.keys(storedDrafts).length > 0 && storedDraftsVersion === 0) {
        await store.current.set('draftsVersion', CURRENT_DRAFT_VERSION);
        storedDraftsVersion = CURRENT_DRAFT_VERSION;
      }

      if (
        Object.keys(storedDrafts).length > 0 &&
        storedDraftsVersion < CURRENT_DRAFT_VERSION
      ) {
        // TODO Migrate into the new draftsVersion
        storedDrafts = {}; // right now, we only delete old drafts
        showToast(`${t('draftsMigrated')}`, 'error');
        await store.current.set('draftsVersion', CURRENT_DRAFT_VERSION);
        await store.current.set('drafts', {});
      }

      dispatchers.initialize(storedDrafts);
    }

    if (!store.current) initStore();
  }, [dispatchers, showToast, t]);

  return {
    drafts,
    ...dispatchers,
  };
};

export default useDraftMapReducer;

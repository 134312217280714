import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Suspense } from 'react';

import { LanguageSwitcher } from '../components/LanguageSwitcher';
import Menu from '../components/Menu/Menu';
import { PushSubscriber } from '../components/PushSubscriber';
import ironRoutes from '../routes';
import type { User } from '@auth0/auth0-react';
import ContactContext from '../services/auth/ContactContext';
import useI18n from '../services/i18n';
import { OrderDraftsContext } from '../services/orderDraft';
import FallbackPage from './FallbackPage';
import ChatContext from '../components/Chat/ChatContext';

export const ContactApp: React.FC<{ user: User }> = ({ user }): JSX.Element => {
  const { lang } = useI18n();

  if (!user?.email) throw new Error('No email from user!');

  return (
    <ContactContext user={user}>
      <PushSubscriber />
      <LanguageSwitcher />
      <IonApp>
        <IonReactRouter>
          {/* <AppUrlOpenListener /> */}
          <IonSplitPane contentId="main">
            <Menu key={`menu-${lang}`} />
            <Suspense fallback={<FallbackPage />}>
              <OrderDraftsContext>
                <ChatContext>
                  <IonRouterOutlet id="main">{ironRoutes}</IonRouterOutlet>
                </ChatContext>
              </OrderDraftsContext>
            </Suspense>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </ContactContext>
  );
};

export default ContactApp;

import type { CameraSource } from '@capacitor/camera';
import {
  IonCol,
  IonIcon,
  IonImg,
  IonProgressBar,
  IonRow,
  IonText,
} from '@ionic/react';
import { cloudUpload } from 'ionicons/icons';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

import type { CloudinaryUploadedImage } from '../../services/cloudinary';
import { capture, upload } from '../../services/cloudinary';
import { useI18n } from '../../services/i18n';

export type ImageUploadRef = {
  reset: () => void;
  upload: () => Promise<CloudinaryUploadedImage>;
} | null;

export interface ImageUploadProps {
  className?: string;
  src?: string;
  folder: string;
  onCapture: (imageAsBase64String: string) => void;
  source?: 'PROMPT' | 'CAMERA' | 'PHOTOS';
  format?: boolean;
  register?: boolean;
}

export const PLACEHOLDER_SRC = '/assets/images/defaultImage.jpeg';

export const ImageUploadRound = forwardRef<ImageUploadRef, ImageUploadProps>(
  (
    {
      className,
      src,
      folder,
      onCapture,
      source = 'CAMERA',
      format,
      register,
    }: ImageUploadProps,
    ref
  ): JSX.Element => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [captured, setCaptured] = useState<string>();
    const { t } = useI18n();

    const wordToSearch = src?.search('https://res.cloudinary.com');
    const srcUrl = src?.slice(0, 48);
    const formatFaceDetection = 'c_thumb,g_faces,h_150,w_150,r_max/';
    const srcImage = src?.slice(48);
    const srcWithFormat = srcUrl + formatFaceDetection + srcImage;
    const startsWith = srcWithFormat?.slice(0, 9);

    const imgSrc = () => {
      if (captured) {
        return `data:image/jpeg;base64,${captured}`;
      } else if (wordToSearch === -1) {
        return src;
      } else if (startsWith === 'undefined') {
        return PLACEHOLDER_SRC;
      } else {
        return srcWithFormat;
      }
    };

    useImperativeHandle(ref, () => ({
      reset: () => setCaptured(undefined),
      upload: async () => {
        if (!captured) throw "No image has been captured, can't upload!";
        const publicId = `${folder}/${Date.now().toString()}`;
        setIsUploading(true);
        const loadPromise = await upload(publicId, captured);
        setIsUploading(false);
        setCaptured(undefined);
        return loadPromise;
      },
    }));

    const shoot = async () => {
      const imageAsBase64String = await capture(source as CameraSource);
      setCaptured(imageAsBase64String);
      onCapture(imageAsBase64String);
    };

    const handleFormat = (jsxElement: JSX.Element) => {
      if (format) {
        return (
          <IonRow className="ion-justify-content-center">
            <IonCol
              size="auto"
              className="ion-padding-vertical ion-text-center"
            >
              {jsxElement}
            </IonCol>
          </IonRow>
        );
      } else {
        return jsxElement;
      }
    };

    return (
      <>
        {handleFormat(
          <Frame className={className}>
            <Image
              className="ion-text-center"
              draggable="false"
              src={imgSrc()}
              onClick={shoot}
              round={true}
            />
            <Corner />
            <Icon icon={cloudUpload} />
            {isUploading && <ProgressBar type="indeterminate" />}
          </Frame>
        )}

        {captured && (
          <IonRow className="ion-text-center ion-padding-bottom">
            <IonCol>
              <Text>
                {register ? t('adviseNewContact') : t('adviseUpdateContact')}
              </Text>
            </IonCol>
          </IonRow>
        )}
      </>
    );
  }
);

ImageUploadRound.displayName = 'ImageUploadRound';

export default ImageUploadRound;

const Frame = styled.div`
  position: relative;
  overflow: hidden;
  width: 6.25em;
  height: 5.9em;
  text-align: center;

  @media screen and (min-width: 700px) {
    width: 12.5em;
    height: 11.87em;
    text-align: center;
  }
`;

const Corner = styled.div`
  position: absolute;
  right: 0.3em;
  bottom: 0.4em;

  width: 2em;
  height: 2em;

  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`;

const Icon = styled(IonIcon)`
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  font-size: clamp(0.9rem, 1vw + 1rem, 2.5rem);
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
`;

const Text = styled(IonText)`
  color: var(--ion-color-warning-shade);
`;

interface ImageProps {
  round?: boolean;
}

const Image = styled(IonImg)<ImageProps>`
  ::part(image) {
    user-drag: none;
    ${(props) => props.round && 'border-radius: 100%'}
  }
  height: 100%;
`;

const ProgressBar = styled(IonProgressBar)`
  position: absolute;
  bottom: 0;
`;

import { Storage } from '@ionic/storage';

const store = new Storage();

// create the store with an IIFE (immediately invoked function expression)
(async function () {
  console.debug('initializing storage');
  return await store.create();
})();

export async function getSetting(key: string): Promise<any> {
  return await store.get(key);
}

export async function setSetting(key: string, value: any): Promise<any> {
  return await store.set(key, value);
}

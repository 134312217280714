import Ably from 'ably';
import { AblyProvider } from 'ably/react';
import { ReactNode, useMemo } from 'react';
import { useChannel } from 'ably/react';

export const CONTACT_UPDATED_EVENT = 'contact.updated';

interface MessagingProviderProps {
  clientId: string;
  children: ReactNode;
}

export default function MessagingProvider({
  clientId,
  children,
}: MessagingProviderProps): JSX.Element {
  const client = useMemo(
    () =>
      new Ably.Realtime.Promise({
        key: process.env.IRON_ABLY_API_KEY,
        clientId,
      }),
    [clientId]
  );
  return <AblyProvider client={client}>{children}</AblyProvider>;
}

type Message = Ably.Types.Message;

interface OnMessageProps {
  channel: string;
  callback: (msg: Message) => void;
}

export function OnMessage({ channel, callback }: OnMessageProps) {
  useChannel(channel, callback);
  return null;
}

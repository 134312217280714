import { useEffect } from 'react';

import type { LanguageType } from '../dtos';
import { useCurrentContact } from '../services/auth/ContactContext';
import useI18n from '../services/i18n';

export const LanguageSwitcher = (): any => {
  const contact = useCurrentContact();

  // TODO: Not sure if this is the right place to set the language
  const { lang, setLang } = useI18n();
  const supplierLang = contact.supplier.language;
  useEffect(() => {
    if (supplierLang && supplierLang !== lang)
      setLang(contact.supplier.language as LanguageType);
  }, [contact.supplier.language, lang, setLang, supplierLang]);

  return null;
};

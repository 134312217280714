import {
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import styled from 'styled-components';

import WithSecurityLevel from '../../pages/SubMenu/SubItemList/WithSecurityLevel';
import { useCurrentContact } from '../../services/auth/ContactContext';
import { useAuth0 as useAuth } from '@auth0/auth0-react';
import { useI18n } from '../../services/i18n';

import './Menu.css';
import { appPages } from './MenuData';
import ImageViewer from '../ImageViewer';

const Menu: React.FC = () => {
  const [showPopover, setShowPopover] = useState(false);

  const { logout } = useAuth();
  const contact = useCurrentContact();
  const { t } = useI18n();

  const supplier = contact.supplier;
  const imageUrl = supplier.imageUrl;

  return (
    <IonMenu contentId="main" type="overlay">
      <IonToolbar color="primary">
        <IonRow>
          <>
            <IonCol size="2.5" className="ion-margin-start">
              <Image
                draggable={false}
                height={'50px'}
                width={'50px'}
                src={imageUrl}
                onClick={() => setShowPopover(true)}
              />
            </IonCol>
            <IonRow className="ion-align-items-center">
              <IonCol>
                <IonRow>
                  <IonLabel>
                    <b>{contact?.name}</b>
                  </IonLabel>
                </IonRow>
                <IonRow>
                  <TextSupplierName color="light">
                    {supplier?.name}
                  </TextSupplierName>
                </IonRow>
              </IonCol>
            </IonRow>
          </>
        </IonRow>
      </IonToolbar>

      <ImageViewer
        src={imageUrl}
        isOpen={showPopover}
        onDismiss={() => setShowPopover(false)}
      />

      <IonContent>
        <IonList id="inbox-list">
          {appPages.map((appPage, index) => {
            return (
              <WithSecurityLevel
                key={index}
                pageAccessLevel={appPage.accessLevel}
                pageSubscriptionLevel={appPage.subscriptionLevel}
                isPageAccessLevelNegativeOneException={
                  appPage?.isPageAccessLevelNegativeOneException
                }
              >
                <IonMenuToggle autoHide={false}>
                  <ItemFather
                    routerDirection="root"
                    detail={false}
                    lines="none"
                    routerLink={appPage.url ?? `/subMenu/${appPage.title}`}
                    onClick={
                      appPage.isLogout
                        ? () =>
                            logout({
                              logoutParams: {
                                returnTo: window.location.origin,
                              },
                            })
                        : undefined
                    }
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel>{t(appPage.title)}</IonLabel>
                  </ItemFather>
                </IonMenuToggle>
              </WithSecurityLevel>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

const TextSupplierName = styled(IonText)`
  font-size: 0.9rem;
`;

const Image = styled.img`
  border-radius: 50%;
`;

const ItemFather = styled(IonItem)`
  --background: var(--ion-color-light-shade);
`;

import { withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import i18next from 'i18next';
import { useEffect } from 'react';

import Loading from '../components/Loading';
import { useAuth0 as useAuth } from '@auth0/auth0-react';

import ContactApp from './ContactApp';

let getToken = async () => Promise.resolve('xxxxxxxxxxxxxxxxxxxx');

axios.interceptors.request.use(
  async (config) => {
    const url = config.url ? new URL(config.url) : undefined;
    const goesToOurAPI =
      (url?.hostname.includes('echtech.mx') ||
        url?.hostname.includes('localhost')) &&
      url?.pathname.startsWith('/v1/api');

    if (config.headers && goesToOurAPI) {
      config.headers['Accept-Language'] = i18next.language;
      // Add timezone heeader
      // Client timezone support implemented according to:
      // https://www.bennadel.com/blog/3588-reporting-the-users-timezone-offset-to-the-server-using-an-api-client-in-angular-7-2-10.htm
      config.headers['X-Timezone'] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers['X-Timezone-Offset'] = String(
        new Date().getTimezoneOffset()
      );

      // JWT token for our backend
      const token: string = await getToken();
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log('An error ocurred when sending request', error);
  }
);

// This must run inside an AuthContext
export const AuthenticatedApp = withAuthenticationRequired(() => {
  const { user, isLoading, error, getAccessTokenSilently } = useAuth();

  useEffect(() => {
    getToken = async () => await getAccessTokenSilently();
  }, [getAccessTokenSilently]);

  if (error) throw error;
  if (isLoading) return <Loading message="Authorizing app" />;
  if (!user) throw new Error('No user!');
  if (!user.email) throw new Error('No email from user?!');

  return <ContactApp user={user} />;
});

import { useIonToast } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useCallback } from 'react';

interface ToastProps {
  showToast: (message: string, type?: 'success' | 'error') => Promise<void>;
}

export const useToast = (): ToastProps => {
  const [present, dismiss] = useIonToast();
  const showToast = useCallback ((message: string, type: 'success' | 'error' = 'success'): Promise<void> => {
    switch (type) {
      case 'error':
        return present({
          message,
          color: 'danger',
          buttons: [
            {
              icon: close,
              side: 'end',
              handler: dismiss,
            },
          ],
        });
      case 'success':
        return present(message, 3000);
    }
  }, [dismiss, present]);

  return {
    showToast,
  };
};

export default useToast;

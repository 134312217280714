import {
  homeOutline,
  cartOutline,
  storefrontOutline,
  listOutline,
  logOutOutline,
  calendarOutline,
  settingsOutline,
} from 'ionicons/icons';

export interface MenuItem {
  iosIcon?: string;
  mdIcon?: string;
  title: string;
  url?: string;
  accessLevel: number;
  subscriptionLevel: number;
  isPageAccessLevelNegativeOneException?: boolean;
  // special marker to allow calling the logout function obtained from useAuth hook elsewhere
  isLogout?: boolean;
}

export const appPages: MenuItem[] = [
  {
    accessLevel: 0,
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    subscriptionLevel: 0,
    title: 'home',
    url: '/',
  },
  {
    accessLevel: 1,
    iosIcon: '../assets/icon/inventories.svg',
    mdIcon: '../assets/icon/inventories.svg',
    subscriptionLevel: 0,
    title: 'inventories',
  },
  {
    accessLevel: 0,
    iosIcon: cartOutline,
    mdIcon: cartOutline,
    subscriptionLevel: 0,
    title: 'purchases',
  },
  {
    accessLevel: 0,
    iosIcon: storefrontOutline,
    mdIcon: storefrontOutline,
    subscriptionLevel: 0,
    title: 'sales',
  },
  {
    accessLevel: 2,
    iosIcon: '../assets/icon/bankBillFinance.svg',
    mdIcon: '../assets/icon/bankBillFinance.svg',
    subscriptionLevel: 0,
    title: 'credits',
  },
  {
    accessLevel: -1,
    iosIcon: '../assets/icon/service.svg',
    mdIcon: '../assets/icon/service.svg',
    subscriptionLevel: 0,
    title: 'deliveries',
  },
  {
    accessLevel: 0,
    iosIcon: listOutline,
    mdIcon: listOutline,
    subscriptionLevel: 0,
    title: 'tasks',
  },
  {
    accessLevel: 0,
    iosIcon: calendarOutline,
    mdIcon: calendarOutline,
    subscriptionLevel: 0,
    title: 'administration',
  },
  {
    accessLevel: 1,
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
    subscriptionLevel: 0,
    title: 'settings',
  },
  {
    accessLevel: -1,
    isPageAccessLevelNegativeOneException: true,
    iosIcon: logOutOutline,
    mdIcon: logOutOutline,
    subscriptionLevel: 0,
    title: 'logOut',
    isLogout: true,
    url: '/',
  },
];

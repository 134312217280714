import { IonPage } from '@ionic/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface ServerError {
  statusCode: number;
  message: string;
  error: string;
}

interface UnexpectedErrorProps {
  error?: Error;
  message?: string;
}

export const UnexpectedError = ({
  error,
  message,
}: UnexpectedErrorProps): JSX.Element => {
  const [t] = useTranslation();

  let errorDetails = message;
  if (error && axios.isAxiosError(error) && error.response?.data) {
    errorDetails = (error.response.data as ServerError).message;
  }

  return (
    <IonPage title={'Oooops!'}>
      <Frame>
        <h1>{t('weAreSorry')}</h1>
        <h2>{errorDetails ? t(errorDetails) : t('noErrorDetailsProvided')}</h2>
      </Frame>
    </IonPage>
  );
};

export default UnexpectedError;

const Frame = styled.div`
  height: 60%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

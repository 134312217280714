import type { ComponentType, FC } from 'react';
import { Redirect } from 'react-router';

import { accessRegistry } from '../accessRegistry';
import type { SubscriptionPlan } from '../dtos';

import { useCurrentContact } from './auth/ContactContext';

const defaultOnRedirecting = (): JSX.Element => <Redirect to="/" />;

const withSecurityLevel = <P extends Record<string, any>>(
  Component: ComponentType<P>
): FC<P> => {
  return function WithSecurityLevelRequired(props: P): JSX.Element {
    const contact = useCurrentContact();

    const accessLevel = contact.accessLevel;
    const subscriptionPlan = contact.supplier.subscriptionPlan;

    const currentPage = accessRegistry.find(
      ({ url }) => url === props.match.path.split('/:')[0]
    );

    const userHasAccess = isUserAuthorized({
      userAccessLevel: accessLevel,
      userSubscriptionPlan: subscriptionPlan,
      pageAccessLevel: currentPage?.accessLevel,
      pageSubscriptionLevel: currentPage?.subscriptionLevel,
      isPageAccessLevelNegativeOneException:
        currentPage?.isPageAccessLevelNegativeOneException,
    });

    return userHasAccess ? <Component {...props} /> : defaultOnRedirecting();
  };
};

export function isUserAuthorized({
  pageAccessLevel,
  pageSubscriptionLevel,
  userAccessLevel,
  userSubscriptionPlan,
  isPageAccessLevelNegativeOneException = false,
}: {
  pageAccessLevel?: number;
  pageSubscriptionLevel?: number;
  userAccessLevel: number;
  userSubscriptionPlan: SubscriptionPlan;
  isPageAccessLevelNegativeOneException?: boolean;
}): boolean {
  if (pageAccessLevel === -1) {
    return userAccessLevel === -1 || isPageAccessLevelNegativeOneException;
  }

  if (
    typeof pageAccessLevel === 'number' &&
    pageAccessLevel <= userAccessLevel &&
    typeof pageSubscriptionLevel === 'number' &&
    pageSubscriptionLevel <= userSubscriptionPlan.id - 1
  ) {
    return true;
  }

  return false;
}

export default withSecurityLevel;

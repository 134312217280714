import { Auth0Provider } from '@auth0/auth0-react';

const auth0Domain = process.env.IRON_AUTH0_DOMAIN;
const auth0ClientId = process.env.IRON_AUTH0_CLIENT_ID;

const loginRedirectUri = window.location.origin;

const createRedirectCallback = (history: any) => (appState: any) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

type Auth0AppProps = {
  children: JSX.Element;
  history: any;
};

export default function AuthContext({ children, history }: Auth0AppProps): any {
  if (!auth0Domain) throw 'Please define auth0 domain environment variable';
  if (!auth0ClientId)
    throw 'Please define auth0 client id environment variables';

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      onRedirectCallback={createRedirectCallback(history)}
      authorizationParams={{
        redirect_uri: loginRedirectUri,
        audience: process.env.IRON_AUTH0_AUDIENCE,
      }}
    >
      {children}
    </Auth0Provider>
  );
}

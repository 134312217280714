import { IonLoading } from '@ionic/react';
import React from 'react';

import useI18n from '../services/i18n';

interface LoadingProps {
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
  const { t } = useI18n();

  return (
    <IonLoading
      duration={Infinity}
      isOpen
      message={message || t('pleaseWait')}
    />
  );
};

export default Loading;

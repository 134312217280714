/*
  Basic image capture functionality using Capacitor, along
  with functionality to upload and remove files to/from
  Cloudinary.
*/

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { t } from 'i18next';

// TODO This variables should be passed in the environment
const CLOUD_NAME = 'echtech';
const API_KEY = '873847941458193';
const API_SECRET = 'DmYKJjNQ8UqADUoscj7SCRJrcrA';
const UPLOAD_PRESET = 'IronUpload';

/**
 * Type for uploads returned from Cloudinary
 */
export interface CloudinaryUploadedImage {
  asset_id: string;
  public_id: string;
  version: number;
  version_id: string;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: string;
  tags: string[];
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  folder: string;
  access_mode: string;
  original_filename: string;
}

/**
 * Capture an image using the device's camera
 *
 * @returns a base64 string representing the
 * captured image
 */
export async function capture(
  source: CameraSource = CameraSource.Camera
): Promise<string> {
  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Base64,
    quality: 100,
    source,
    promptLabelHeader: t('selectAnOption'),
    promptLabelPhoto: t('gallery'),
    promptLabelPicture: t('camera'),
  });

  if (!photo.base64String) throw 'Could not get access to the camera';
  return photo.base64String;
}

/**
 * Upload an image to cloudinary
 *
 * @param publicId The full public id for the file, including folder
 * @param image The image to upload, encoded as a base64 string
 * @returns
 */
export async function upload(
  publicId: string,
  image: string
): Promise<CloudinaryUploadedImage> {
  const formData = new FormData();
  formData.append('api_key', API_KEY);
  formData.append('public_id', publicId);
  formData.append('file', makeBlob(image));
  formData.append('overwrite', 'true');
  formData.append('upload_preset', UPLOAD_PRESET);
  await signRequest(formData);

  const response = await axios.post<
    FormData,
    AxiosResponse<CloudinaryUploadedImage>
  >(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * Remove the given file from Cloudinary
 *
 * @param publicId The unique id for the file
 */
export async function remove(publicId: string): Promise<void> {
  const formData = new FormData();
  formData.append('api_key', API_KEY);
  formData.append('public_id', publicId);
  await signRequest(formData);

  const response = await axios.post<
    FormData,
    AxiosResponse<Record<string, string>>
  >(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/destroy`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  if (response.data.result !== 'ok')
    throw 'Could not remove temporal image from cloud';
}

/**
 * Convert from a base64 string to a blob
 *
 * @param imageAsBase64String
 * @returns A blob made from the string
 */
function makeBlob(imageAsBase64String: string): Blob {
  const rawData = atob(imageAsBase64String);
  const bytes = new Array(rawData.length);
  for (let x = 0; x < rawData.length; x++) {
    bytes[x] = rawData.charCodeAt(x);
  }
  const arr = new Uint8Array(bytes);
  return new Blob([arr], { type: 'image/png' });
}

/**
 * Signs a request according to Cloudinary requirements.
 *
 * This function modifies the input data to add the signature
 *
 * @param data FormData to sign
 */
async function signRequest(/* mutable */ data: FormData): Promise<string> {
  const SIGNATURE_EXCLUDED_KEYS = [
    'file',
    'cloud_name',
    'resource_type',
    'api_key',
  ];
  data.append('timestamp', Date.now().toString());
  const inputString = Array.from(data)
    .filter(([key]) => !SIGNATURE_EXCLUDED_KEYS.includes(key))
    .sort(([a], [b]) => compare(a, b))
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
    .concat(API_SECRET);

  const signature = await hashString(inputString);
  data.append('signature_algorithm', 'SHA-256');
  data.append('signature', signature);
  return signature;
}

function compare(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

/**
 * Create a SHA-256 signature for the given string
 *
 * @param str the input string
 * @returns SHA-256 signature for the input string
 */
async function hashString(str: string) {
  const utf8 = new TextEncoder().encode(str);
  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
}

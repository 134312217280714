import type { AxiosError } from 'axios';
import axios from 'axios';
import { addSeconds, getUnixTime, parseISO } from 'date-fns';

/**
 *  Create a query string suitable to use in an URL
 * @param obj An object to hold all the parameters
 * @returns A query object like "?a=1&b=2" or an empty string if the input object is empty
 */
export function makeServiceUrl(
  service: string,
  obj?: Record<string, any>
): string {
  const runningInCosmos = location.host === 'localhost:5000';
  const localApiUrl = runningInCosmos
    ? 'http://localhost:3000/v1/api'
    : `${location.origin}/v1/api`;
  const apiEndpoint = process.env.IRON_API_URL ?? localApiUrl;

  const params = new URLSearchParams();
  if (obj) {
    for (const key of Object.keys(obj)) {
      const value: string = obj[key] ?? '';
      if (value !== '') {
        params.append(key, value.toString());
      }
    }
  }
  const paramsString = new URLSearchParams(params).toString();
  const baseUrl = `${apiEndpoint}/${service}/`;
  return paramsString ? `${baseUrl}?${paramsString}` : baseUrl;
}

// Use this function in a try…catch block when you need an error message from
// the error you catched, which should be unknown
export function getErrorMessage(error: unknown): string {
  if (axios.isAxiosError(error)) {
    const e: AxiosError = error;

    const data = e.response?.data as AxiosErrorData | undefined;
    return data?.message ?? e.message;
  }
  if (error instanceof Error) return error.message;
  return String(error);
}

interface AxiosErrorData {
  error: string;
  message: string;
  statusCode: number;
}

export const removeAccents = (txt = ''): string => {
  const text = txt ? txt : '';
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export function truncateDecimals(number = 0, decimals: number): number {
  const factor = Math.pow(10, decimals);
  const truncatedNumber = Math.trunc(number * factor) / factor;
  return truncatedNumber;
}

export const escapeRegExp = (text: string) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export function divideSafely(numerator: number, denominator: number) {
  return denominator === 0 ? 0 : numerator / denominator;
}

import type { CurrencyCode, MaturityOption, OrderItemsDraft } from '../../dtos';

import type { Action } from './reducer';
import type { DraftMap, OrderKind } from './types';

// internal function
const _createDispatchers = (dispatch: (action: Action) => void) => {
  return {
    initialize: (drafts: DraftMap) => dispatch({ type: 'initialize', drafts }),
    deleteDraft: (draftKey: string) =>
      dispatch({ type: 'deleteDraft', draftKey }),
    setService: (
      draftKey: string,
      serviceId: number,
      deliveryInstructionId?: number,
      pickupAddressId?: number
    ) =>
      dispatch({
        type: 'setService',
        draftKey,
        serviceId,
        deliveryInstructionId,
        pickupAddressId,
      }),
    pushItem: (draftKey: string, items: OrderItemsDraft[]) =>
      dispatch({ type: 'pushItem', draftKey, items }),
    popItem: (draftKey: string, itemIndex = 0) =>
      dispatch({ type: 'popItem', draftKey, itemIndex }),
    setRequestedQuantity: (
      draftKey: string,
      itemIndex: number,
      requestedQuantity: number
    ) =>
      dispatch({
        type: 'setRequestedQuantity',
        draftKey,
        itemIndex,
        requestedQuantity,
      }),
    createDraft: (
      kind: OrderKind,
      sellerId: number,
      buyerId: number,
      currencyCode: CurrencyCode,
      currencyId: number,
      serviceId: number | undefined,
      items: OrderItemsDraft[],
      pickupAddressId?: number,
      deliveryInstructionId?: number
    ) =>
      dispatch({
        type: 'createDraft',
        kind,
        sellerId,
        buyerId,
        currencyCode,
        currencyId,
        serviceId,
        items,
        pickupAddressId,
        deliveryInstructionId,
      }),
    deleteAllDrafts: () => dispatch({ type: 'deleteAllDrafts' }),
    deleteAllItems: (draftKey: string) =>
      dispatch({ type: 'deleteAllItems', draftKey }),
    setDeliveryInstructionId: (
      draftKey: string,
      deliveryInstructionId: number
    ) =>
      dispatch({
        type: 'setDeliveryInstructionId',
        draftKey,
        deliveryInstructionId,
      }),
    deleteBuyerDrafts: (buyerId: number, from: string) =>
      dispatch({ type: 'deleteBuyerDrafts', buyerId, from }),
    setBuyerId: (draftKey: string, buyerId: number) =>
      dispatch({ type: 'setBuyerId', draftKey, buyerId }),
    createOrUpdateDraftFromShoppingList: (
      data: {
        sellerId: number;
        buyerId: number;
        currencyCode: CurrencyCode;
        currencyId: number;
        item: OrderItemsDraft;
      }[]
    ) => dispatch({ type: 'createOrUpdateDraftFromShoppingList', data }),
    setMaturity: (
      draftKey: string,
      itemIndex: number,
      maturityOption: MaturityOption
    ) =>
      dispatch({
        type: 'setMaturity',
        draftKey,
        itemIndex,
        maturityOption,
      }),
    setOrderItemQuote: (
      draftKey: string,
      itemIndex: number,
      orderItem: OrderItemsDraft
    ) =>
      dispatch({
        type: 'setOrderItemQuote',
        draftKey,
        itemIndex,
        orderItem,
      }),
    setSubTotal: (draftKey: string, itemIndex: number, subTotal: number) =>
      dispatch({
        type: 'setSubTotal',
        draftKey,
        itemIndex,
        subTotal,
      }),
  };
};

export type DispatchersObject = ReturnType<typeof _createDispatchers>;

export const createDispatchers = (
  dispatch: (action: Action) => void
): DispatchersObject => _createDispatchers(dispatch);

export const createDefaultDispatchers = (): DispatchersObject =>
  createDispatchers(defaultDispatcher);

const defaultDispatcher = () => {
  console.error('You should not be calling this default implementation!');
};

import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useRef,
} from 'react';

import { useAbly, useChannel } from 'ably/react';
import { useCurrentContact } from '../../services/auth/ContactContext';
import { ChatMessage } from '../../dtos';
import useChatReducer from './useChatReducer';
import { Types } from 'ably';

interface ChatContextProps {
  startChat: (chatId: string) => void;
  sendMessage: (message: ChatMessage) => void;
  unreadMessageCounts: Record<string, number>;
  markMessageAsRead: (supplierRecipientId: number, chatId: string) => void;
  conversationChannel: Types.RealtimeChannelPromise | null;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const CHANNEL_MESSAGE_RECEIVED_EVENT = 'channel.message.received';

//default channels
const CHANNEL_MARKASREAD = 'channel.markasread';
const CHANNEL_UNREADMESSAGES = 'channel.unreadmessages';

const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { channels } = useAbly();
  const { supplierId } = useCurrentContact();
  const { current: markAsReadChannel } = useRef(
    channels.get(CHANNEL_MARKASREAD)
  );
  const { setState, setUnreadMessageCounts, state } = useChatReducer();
  const { conversationChannel, unreadMessageCounts } = state;

  const startChat = useCallback(
    (channelKey: string) => {
      const channel = channels.get(channelKey);

      setState({ conversationChannel: channel });
    },
    [channels]
  );

  useChannel(CHANNEL_UNREADMESSAGES, (m) => {
    if (supplierId === m.data.supplierRecipientId) {
      setUnreadMessageCounts({
        [m.data.chatId]: m.data.unreadMessagesCountByChat,
        [supplierId]: m.data.unreadMessagesCountBySupplier,
      });
    }
  });

  const sendMessage = (message: ChatMessage) => {
    if (!conversationChannel) return;
    conversationChannel.publish(CHANNEL_MESSAGE_RECEIVED_EVENT, message);
  };

  const markMessageAsRead: ChatContextProps['markMessageAsRead'] = (
    supplierRecipientId,
    _chatId
  ) => {
    console.log('marking as read');
    markAsReadChannel.publish(CHANNEL_MESSAGE_RECEIVED_EVENT, {
      supplierRecipientId,
      chatId: _chatId,
    });
  };

  return (
    <ChatContext.Provider
      value={{
        unreadMessageCounts,
        startChat,
        sendMessage,
        markMessageAsRead,
        conversationChannel,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = (): ChatContextProps => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};

export default ChatProvider;

import { setupIonicReact } from '@ionic/react';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { LoadScript } from '@react-google-maps/api';
import { createBrowserHistory } from 'history';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import AuthContext from '../services/auth/AuthContext';

/* Theme variables */
import '../theme/variables.css';
import { AuthenticatedApp } from './AuthenticatedApp';
import ErrorBoundary from './ErrorBoundary';

import { registerSW } from 'virtual:pwa-register';

registerSW({ immediate: true });

setupIonicReact({
  mode: 'md',
});

const history = createBrowserHistory();

// Create a react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: (error: any) => error.response?.status >= 500,
      // refetch on focus only in production
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
    },
  },
});

const App: React.FC = () => {
  return (
    <AuthContext history={history}>
      <QueryClientProvider client={queryClient}>
        {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />} */}
        <LoadScript googleMapsApiKey={process.env.IRON_GOOGLE_MAPS_KEY ?? ''}>
          <ErrorBoundary>
            <AuthenticatedApp />
          </ErrorBoundary>
        </LoadScript>
      </QueryClientProvider>
    </AuthContext>
  );
};

export default App;

import type { CurrencyCode, MaturityOption, OrderItemsDraft } from '../../dtos';

import type { DraftMap, OrderKind } from './types';

export default reducer;

export type Action =
  | { type: 'initialize'; drafts: DraftMap }
  | { type: 'deleteDraft'; draftKey: string }
  | {
      type: 'setService';
      draftKey: string;
      serviceId: number;
      deliveryInstructionId?: number;
      pickupAddressId?: number;
    }
  | {
      type: 'setDeliveryInstructionId';
      draftKey: string;
      deliveryInstructionId?: number;
    }
  | { type: 'pushItem'; draftKey: string; items: OrderItemsDraft[] }
  | { type: 'popItem'; draftKey: string; itemIndex: number }
  | {
      type: 'setRequestedQuantity';
      draftKey: string;
      itemIndex: number;
      requestedQuantity: number;
    }
  | {
      type: 'setMaturity';
      draftKey: string;
      itemIndex: number;
      maturityOption: MaturityOption;
    }
  | {
      type: 'setOrderItemQuote';
      draftKey: string;
      itemIndex: number;
      orderItem: OrderItemsDraft;
    }
  | {
      type: 'setSubTotal';
      draftKey: string;
      itemIndex: number;
      subTotal: number;
    }
  | {
      type: 'createDraft';
      kind: OrderKind;
      sellerId: number;
      buyerId: number;
      currencyCode: CurrencyCode;
      currencyId: number;
      serviceId: number | undefined;
      items: OrderItemsDraft[];
      pickupAddressId?: number;
      deliveryInstructionId?: number;
    }
  | {
      type: 'deleteAllDrafts';
    }
  | { type: 'deleteAllItems'; draftKey: string }
  | { type: 'deleteBuyerDrafts'; buyerId: number; from: string }
  | {
      type: 'setBuyerId';
      draftKey: string;
      buyerId: number;
    }
  | {
      type: 'createOrUpdateDraftFromShoppingList';
      data: {
        sellerId: number;
        buyerId: number;
        currencyCode: CurrencyCode;
        currencyId: number;
        item: OrderItemsDraft;
      }[];
    };
function reducer(state: DraftMap, action: Action): DraftMap {
  switch (action.type) {
    case 'initialize':
      return action.drafts;
    case 'deleteDraft':
      return deleteDraft(state, action.draftKey);
    case 'setService':
      return setService(
        state,
        action.draftKey,
        action.serviceId,
        action.deliveryInstructionId,
        action.pickupAddressId
      );
    case 'setDeliveryInstructionId':
      return setDeliveryInstructionId(
        state,
        action.draftKey,
        action.deliveryInstructionId
      );
    case 'pushItem':
      return pushItem(state, action.draftKey, action.items);

    case 'popItem':
      return popItem(state, action.draftKey, action.itemIndex);
    case 'setRequestedQuantity':
      return setRequestedQuantity(
        state,
        action.draftKey,
        action.itemIndex,
        action.requestedQuantity
      );
    case 'setMaturity':
      return setMaturity(
        state,
        action.draftKey,
        action.itemIndex,
        action.maturityOption
      );
    case 'setOrderItemQuote':
      return setOrderItemQuote(
        state,
        action.draftKey,
        action.itemIndex,
        action.orderItem
      );
    case 'setSubTotal':
      return setSubTotal(
        state,
        action.draftKey,
        action.itemIndex,
        action.subTotal
      );
    case 'createDraft':
      return createDraft(
        state,
        action.kind,
        action.sellerId,
        action.buyerId,
        action.currencyCode,
        action.currencyId,
        action.serviceId,
        action.items,
        action.pickupAddressId,
        action.deliveryInstructionId
      );
    case 'deleteAllDrafts':
      return deleteAllDrafts();
    case 'deleteAllItems':
      return deleteAllItems(state, action.draftKey);
    case 'deleteBuyerDrafts':
      return deleteBuyerDrafts(state, action.buyerId, action.from);
    case 'setBuyerId':
      return setBuyerId(state, action.draftKey, action.buyerId);
    case 'createOrUpdateDraftFromShoppingList':
      return createOrUpdateDraftFromShoppingList(state, action.data);
    default:
      return state;
  }
}

const keyFor = (
  kind: OrderKind,
  buyerId: number,
  sellerId: number,
  currencyCode: CurrencyCode
): string => [kind, buyerId, sellerId, currencyCode].join('-');

function createDraft(
  state: DraftMap,
  kind: OrderKind,
  sellerId: number,
  buyerId: number,
  currencyCode: CurrencyCode,
  currencyId: number,
  serviceId: number | undefined,
  items: OrderItemsDraft[],
  pickupAddressId?: number,
  deliveryInstructionId?: number
): DraftMap {
  const key = keyFor(kind, buyerId, sellerId, currencyCode);

  // If we already have a draft for this seller, do nothing
  if (state[key]) {
    return state;
  }
  // Otherwise, create a new draft.
  return {
    ...state,
    [key]: {
      ...state[key],
      orderItemsDraft: [...(state[key]?.orderItemsDraft ?? []), ...items],
      sellerId,
      buyerId,
      supplierServiceId: serviceId,
      pickupAddressId,
      deliveryInstructionId,
      currencyCode,
      currencyId,
    },
  };
}

function deleteDraft(state: DraftMap, draftKey: string): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id');

  // destructure remove draft from state
  const { [draftKey]: _removed, ...rest } = state;
  return rest;
}

function setService(
  state: DraftMap,
  draftKey: string,
  serviceId: number,
  deliveryInstructionId?: number,
  pickupAddressId?: number
): DraftMap {
  if (!state[draftKey]) throw new Error('Invalid draft id');

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      supplierServiceId: serviceId,
      deliveryInstructionId,
      pickupAddressId,
    },
  };
}

function setDeliveryInstructionId(
  state: DraftMap,
  draftKey: string,
  deliveryInstructionId?: number
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id');

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      deliveryInstructionId,
    },
  };
}

function pushItem(
  state: DraftMap,
  draftKey: string,
  items: OrderItemsDraft[]
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      orderItemsDraft: [
        ...(state[draftKey].orderItemsDraft as OrderItemsDraft[]),
        ...items,
      ],
    },
  };
}

function popItem(
  state: DraftMap,
  draftKey: string,
  itemIndex: number
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      orderItemsDraft: state[draftKey]?.orderItemsDraft?.filter(
        (_, i) => i !== itemIndex
      ),
    },
  };
}

function setRequestedQuantity(
  state: DraftMap,
  draftKey: string,
  itemIndex: number,
  requestedQuantity: number
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      orderItemsDraft: state[draftKey]?.orderItemsDraft?.map((item, i) => {
        if (i === itemIndex) return { ...item, requestedQuantity };
        else return item;
      }),
    },
  };
}

function setSubTotal(
  state: DraftMap,
  draftKey: string,
  itemIndex: number,
  subTotal: number
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      orderItemsDraft: state[draftKey]?.orderItemsDraft?.map((item, i) => {
        if (i === itemIndex) return { ...item, subTotal };
        else return item;
      }),
    },
  };
}

function setMaturity(
  state: DraftMap,
  draftKey: string,
  itemIndex: number,
  maturityOption: MaturityOption
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      orderItemsDraft: state[draftKey]?.orderItemsDraft?.map((item, i) => {
        if (i === itemIndex) return { ...item, maturityOption };
        else return item;
      }),
    },
  };
}

function setOrderItemQuote(
  state: DraftMap,
  draftKey: string,
  itemIndex: number,
  orderItem: OrderItemsDraft
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);
  const { itemOrderStatus, subTotal, unitPrice, userId, quote } = orderItem;
  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      orderItemsDraft: state[draftKey]?.orderItemsDraft?.map((item, i) => {
        if (i === itemIndex)
          return {
            ...item,
            itemOrderStatus,
            subTotal,
            unitPrice,
            userId,
            quote,
          };
        else return item;
      }),
    },
  };
}

function deleteBuyerDrafts(
  state: DraftMap,
  supplierId: number,
  from: string
): DraftMap {
  let newState = {};
  Object.entries(state)?.map(([key, cars]) => {
    const { buyerId, sellerId } = cars;
    const buyerOrSeller = from === 'newOrder' ? buyerId : sellerId;
    if (buyerOrSeller !== supplierId) {
      newState = { ...newState, [key]: cars };
    }
  });
  return newState;
}

function deleteAllDrafts(): DraftMap {
  return {};
}

function deleteAllItems(state: DraftMap, draftKey: string): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      orderItemsDraft: [],
      total: 0,
    },
  };
}

function setBuyerId(
  state: DraftMap,
  draftKey: string,
  buyerId: number
): DraftMap {
  // If we don't have a draft for this seller, throw!
  if (!state[draftKey]) throw new Error('Invalid draft id: ' + draftKey);

  return {
    ...state,
    [draftKey]: {
      ...state[draftKey],
      buyerId,
    },
  };
}

function createOrUpdateDraftFromShoppingList(
  state: DraftMap,
  data: {
    sellerId: number;
    buyerId: number;
    currencyCode: CurrencyCode;
    currencyId: number;
    item: OrderItemsDraft;
  }[]
): DraftMap {
  let newState = state;
  data.map(({ buyerId, currencyCode, currencyId, item, sellerId }) => {
    const { draftKey, draft } = findDraft({
      currencyCode,
      drafts: newState,
      from: 'newOrder',
      buyerId,
      sellerId,
    });

    if (!draft) {
      newState = createDraft(
        newState,
        'order',
        sellerId,
        buyerId,
        currencyCode,
        currencyId,
        undefined,
        [item],
        undefined,
        undefined
      );
      return;
    }

    const existingDraftItems = draft?.orderItemsDraft ?? [];
    const { requestedQuantity, supplierPresentationId } = item;

    const index = existingDraftItems.findIndex(
      ({ supplierPresentationId: id }) => id === supplierPresentationId
    );

    const existingItem = existingDraftItems[index];

    const newQuantity =
      (existingItem?.requestedQuantity ?? 0) + requestedQuantity;

    if (!existingItem) {
      newState = pushItem(newState, draftKey, [item]);
      return;
    }

    newState = setRequestedQuantity(newState, draftKey, index, newQuantity);
  });

  return newState;
}

export function findDraft({
  currencyCode,
  drafts,
  buyerId,
  sellerId,
  from,
}: {
  drafts: DraftMap;
  sellerId?: number;
  buyerId?: number;
  currencyCode: string;
  from: string;
}) {
  const [draftKey = '', draft] =
    Object.entries(drafts).find(
      (item) =>
        item[1].sellerId === (from === 'newOrder' ? sellerId : buyerId) &&
        item[1].buyerId === (from === 'newOrder' ? buyerId : sellerId) &&
        item[1].currencyCode === currencyCode
    ) || [];

  return {
    draftKey,
    draft,
  };
}

import type { AxiosResponse } from 'axios';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import Loading from '../../components/Loading';
import type { Contact } from '../../dtos';
import type { User } from '@auth0/auth0-react';
import { makeServiceUrl } from '../utils';
import MessagingProvider, {
  CONTACT_UPDATED_EVENT,
  OnMessage,
} from '../messaging/MessagingProvider';

const Context = React.createContext<Contact | null>(null);

interface ContactContextProps {
  user: User;
  children: ReactNode;
}

export default function ContactContext({
  user,
  children,
}: ContactContextProps): JSX.Element {
  const [contact, setContact] = useState<Contact>();
  const [error, setError] = useState<Error | undefined>();

  const doLogin = useCallback(async () => {
    if (!user.email) return;

    const { contact, error } = await login(user);
    if (error || !contact) setError(error);
    else setContact(contact);
  }, [user]);

  useEffect(() => {
    doLogin();
  }, [doLogin]);

  if (error) throw error;

  if (contact)
    return (
      <Context.Provider value={contact}>
        <MessagingProvider clientId={`${contact.id}`}>
          <OnMessage
            channel={`contact:${contact.id}`}
            callback={(msg) => {
              if (msg.name === CONTACT_UPDATED_EVENT) {
                console.log(CONTACT_UPDATED_EVENT);
                doLogin();
              }
            }}
          />
          {children}
        </MessagingProvider>
      </Context.Provider>
    );
  return <Loading />;
}

export const useCurrentContact = (): Contact => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(Context)!;
};

async function login(user: User) {
  try {
    const { data } = await axios.post<User, AxiosResponse<Contact>>(
      makeServiceUrl('login'),
      user
    );
    console.log(`Contact ${user.email} has been logged in`);
    return { contact: data };
  } catch (error) {
    return {
      error: error as Error,
    };
  }
}

import axios from 'axios';
import { useEffect } from 'react';

import { useCurrentContact } from '../services/auth/ContactContext';
import { makeServiceUrl } from '../services/utils';

export const PushSubscriber = (): any => {
  const { id } = useCurrentContact();

  useEffect(() => {
    if (id) subscribeById(id);
  }, [id]);

  return null;
};

async function subscribeById(id: number) {
  if (!('PushManager' in window && window.location.hostname !== 'localhost')) {
    // console.error('Push service not available');
    return;
  }

  try {
    const reg = await navigator.serviceWorker.getRegistration();
    if (!reg) {
      console.error('No service worker registered');
      return;
    }
    const sub = await reg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.IRON_PUSH_VAPID_PUBLIC_KEY,
    });
    if (sub) {
      const response = await axios.post(makeServiceUrl('pushsubscriptions'), {
        contactId: id,
        subscription: sub,
      });

      if (response.status < 200 || response.status >= 400)
        console.error(
          'Push notification subscription failed: ',
          response.status,
          response.statusText
        );
    }
  } catch (e) {
    console.error(
      'An unkown error occured while subscribing to push notifications',
      JSON.stringify(e)
    );
  }
}
